import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

// services
import { sendToLSQ } from "../../../services/LeadSquared";
import { sendToCT } from "../../../services/Clevertap";

//styles
import * as styles from "./RegisterForm.module.css";

const RegisterForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (props.clientInfo.name) {
      setValue("name", props.clientInfo.name || "", { shouldValidate: true });
      setValue("email", props.clientInfo.email || "", { shouldValidate: true });
      setValue("phone", props.clientInfo.phone || "", { shouldValidate: true });
    }
  }, [
    props.clientInfo.name,
    props.clientInfo.email,
    props.clientInfo.phone,
    setValue,
  ]);

  const onSubmit = async data => {
    // handle header -> for website as well as for app-dx
    if (props.appHeader && props.appHeader === "mobile") {
      navigate(
        `/doctor-consult-scheduling/?id=${props.doctorId}&name=${
          data.name
        }&email=${data.email}&phone=${data.phone}&clientId=${
          props.clientId
        }&appHeader=mobile${
          props.twinApp && props.twinApp === "yes" ? "&twinApp=yes" : ""
        }`
      );
    } else if (props.appHeader && props.appHeader === "no") {
      navigate(
        `/doctor-consult-scheduling/?id=${props.doctorId}&name=${
          data.name
        }&email=${data.email}&phone=${data.phone}&clientId=${
          props.clientId
        }&appHeader=no${
          props.twinApp && props.twinApp === "yes" ? "&twinApp=yes" : ""
        }`
      );
    } else {
      navigate(
        `/doctor-consult-scheduling/?id=${props.doctorId}&name=${
          data.name
        }&email=${data.email}&phone=${data.phone}${
          props.twinApp && props.twinApp === "yes" ? "&twinApp=yes" : ""
        }`
      );
    }

    // leadSquared integration
    let leadSquaredParams = {
      FirstName: data.name,
      Phone: data.phone,
      EmailAddress: data.email,
      mx_Website_CTA:
        props.twinApp && props.twinApp === "yes"
          ? "Doctor Consult Pre-Enroll App"
          : "Doctor Consult Website",
      mx_Latest_Source:
        props.twinApp && props.twinApp === "yes"
          ? "PRE ENROLL APPLICATION"
          : "New Website",
      mx_Product_id: props.productId,
      mx_Product_type: "DOCTOR_CONSULT",
    };

    try {
      // setLoading(true);
      sendToLSQ(leadSquaredParams);

      // clevertap event
      if (props.twinApp && props.twinApp === "yes") {
        // For Twin App
        if (window.twinApp) {
          const ctData = JSON.stringify({
            action: "cleverTapEvent",
            arguments: {
              event: "doctor_consult_initiated",
              payload: {
                action: JSON.stringify({
                  msg: "Register_Now_Cta Clicked",
                  name: data.name,
                  email: data.email,
                  phone: data.phone,
                }),
              },
            },
          });
          window.twinApp.postMessage(ctData);
        }
      } else {
        // For Web
        sendToCT("doctor_consult_initiated", {
          action: "Register_Now_Cta Clicked",
          name: data.name,
          email: data.email,
          phone: data.phone,
        });
      }
    } catch (error) {
      console.error(
        "Leadsquared server error in doctor consult register form",
        error
      );
    }
  };

  return (
    <>
      <div className={styles.regForm_sec}>
        {/* Heading */}
        <div className={styles.regForm_hedngSec}>
          <h3>Confirm your contact info</h3>
        </div>
        {/* Sub-Heading */}
        {/* <div className={styles.regForm_subHedngSec}>
          <p>
            Fill in your details here to book your appointment with top
            Diabetologists
          </p>
        </div> */}
        {/* Desktop Line */}
        <div className={styles.regForm_desktpLine}></div>
        {/* Form */}
        <div className={styles.regForm_formSec}>
          <form name="doctorRegisterForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className={`form-group ${styles.regForm_formInput_sec}`}>
                  <label htmlFor="name">Name</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Full name"
                    id="name"
                    type="text"
                    {...register("name", {
                      required: "Please enter your name",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid name",
                      },
                      maxLength: { value: 50, message: "Name is too long" },
                    })}
                  />
                  {errors.name && (
                    <span className="invalid-feedback">
                      {errors.name.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className={`form-group ${styles.regForm_formInput_sec}`}>
                  <label htmlFor="phone">Phone</label>
                  <input
                    className={`form-control letterSp1
                  ${errors.phone ? "is-invalid" : ""}
                `}
                    placeholder="Contact number"
                    id="phone"
                    type="text"
                    maxLength="10"
                    inputMode="numeric"
                    {...register("phone", {
                      required: "Please enter your mobile number",
                      pattern: {
                        value:
                          /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid phone number",
                      },
                      maxLength: { value: 10, message: "Invalid phone number" },
                    })}
                  />
                  {errors.phone && (
                    <span className="invalid-feedback">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className={`form-group ${styles.regForm_formInput_sec}`}>
                  <label htmlFor="email">Email</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Email id"
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Please enter your email",
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="invalid-feedback">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>

              <div className={`col-md-12 ${styles.regForm_ctaSec}`}>
                <div className="buttonClass">
                  <span className="errorclass d-block text-center text-danger">
                    {!isValid &&
                      isSubmitted &&
                      "Please fill all the fields correctly"}
                  </span>
                  <button type="submit" className={styles.regForm_ctaSec_btn}>
                    <div className="bookBtn" id="book-now">
                      <span
                        className={`d-flex align-items-center justify-content-center position-relative`}
                      >
                        <span className={`bookBtn`}>Continue booking</span>
                        <span
                          className={`icon-arrow-forward ${styles.regForm_ctaSec_icnSec}`}
                          aria-hidden="true"
                        ></span>
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
