import React, { useState } from "react";
import { Spinner } from "react-bootstrap";

// services
import { sendToCT } from "../../../services/Clevertap";

// components
import RegisterFormModal from "../RegisterForm/RegisterFormModal";

// styles
import * as styles from "./DoctorList.module.css";

const DoctorList = props => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [doctorId, setDoctorId] = useState(0);

  const handleBookAppointment = id => {
    setShowRegisterModal(true);
    setDoctorId(id);

    // clevertap event
    if (props.twinApp && props.twinApp === "yes") {
      // For Twin App
      if (window.twinApp) {
        const ctData = JSON.stringify({
          action: "cleverTapEvent",
          arguments: {
            event: `doctor_consult_${id}_clicked`,
            payload: {
              action: JSON.stringify({
                msg: "Doctor_Book_Cta Clicked",
                doctorId: `${id}`,
              }),
            },
          },
        });
        window.twinApp.postMessage(ctData);
      }
    } else {
      // For Web
      sendToCT(`doctor_consult_${id}_clicked`, {
        action: "Doctor_Book_Cta Clicked",
        doctorId: id,
      });
    }
  };

  return (
    <div>
      <div className={`container twinContainer ${styles.docList_sec}`}>
        <div className="row">
          {/* Heading */}
          <div className="col-12 col-xs-12 col-md-12 col=lg-12">
            <div className={styles.docList_hdng}>
              <h3>Choose your doctor</h3>
              {/* Sub-Heading */}
              <p className="d-none">
                Book appointment <span>₹999</span> ₹199{" "}
              </p>
            </div>
          </div>
        </div>
        {/* Desktop Line */}
        <div className={`${styles.docList_dsktpLine_1}`}></div>
        {/* Show doctors list */}
        {props.loading ? (
          <div className="d-block text-center">
            <Spinner animation="grow" role="status" />
          </div>
        ) : (
          <div className="row">
            {props.doctorList.length > 0
              ? props.doctorList.map((data, i) => {
                  return (
                    <div
                      className={
                        props.doctorList.length === 1
                          ? `col-12 col-xs-12 col-md-6 col-lg-6 ${styles.docList_singleDocList}`
                          : "col-12 col-xs-12 col-md-6 col-lg-6"
                      }
                    >
                      <div className={`row ${styles.docList_detailsSec}`}>
                        <div className="col-4 col-xs-4 col-md-4 col-lg-4">
                          {/* doctor image */}
                          <div className={`pt-3 ${styles.docList_docImg}`}>
                            <img
                              src={data.profilePhoto}
                              alt=""
                              placeholder="blurred"
                              style={{
                                borderRadius: "55px",
                              }}
                            />
                          </div>

                          {/* doctor verified text */}
                          {data.verifiedTag ? (
                            <div
                              className={`d-flex align-items-center ${styles.docList_verifySec}`}
                            >
                              <span
                                className={`icon-04`}
                                aria-hidden="true"
                                style={{ color: "#219653" }}
                              ></span>
                              <p>VERIFIED</p>
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={`col-8 col-xs-8 col-md-8 col-lg-8 pt-3 ${styles.docList_rightSec}`}
                        >
                          {/* doctor title */}
                          <div className={styles.docList_docTitle}>
                            <h3>{data.name}</h3>
                          </div>

                          {/* doctor designation */}
                          <div className={styles.docList_docDsigntn}>
                            {/* <p>MBBS, MS, MSc Diabetology (UK)</p> */}
                            <p>{data.designation}</p>
                          </div>
                          {/* doctor language */}
                          <div className={styles.docList_docLan}>
                            {/* <p>Speaks English, Tamil</p> */}
                            <p>Speaks {data.language}</p>
                          </div>
                          {/* doctor experience */}
                          <div className={styles.docList_docExp}>
                            {/* <p>10+ years of experience</p> */}
                            <p>
                              {data.experience}+ {data.experienceDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* doctor appointment cta */}
                      {props.coupanEnabled ? (
                        <div className={styles.docList_bookCta}>
                          <button
                            onClick={() => handleBookAppointment(data.id)}
                          >
                            Book appointment @ <span>₹{data.costPrice}</span> ₹
                            {data.costPrice - props.discountedPrice}
                          </button>
                        </div>
                      ) : (
                        <div className={styles.docList_bookCta}>
                          <button
                            onClick={() => handleBookAppointment(data.id)}
                          >
                            Book appointment @ ₹{data.costPrice}
                          </button>
                        </div>
                      )}
                      {/* Desktop Line */}
                      <div className={`${styles.docList_dsktpLine_2}`}></div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
      </div>

      <RegisterFormModal
        showCanvasModal={showRegisterModal}
        onHideCanvasModal={() => setShowRegisterModal(false)}
        windowSize={props.windowSize}
        doctorId={doctorId}
        productId={props.productId}
        appHeader={props.appHeader}
        clientInfo={props.clientInfo}
        twinApp={props.twinApp}
        clientId={props.clientId}
      />
    </div>
  );
};

export default DoctorList;
